/* eslint-disable no-restricted-imports */
import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { addDays, startOfDay, format, isAfter, subHours } from 'date-fns';

import ScheduleDisplay from 'ui/components/molecules/schedule-display';

import Idle from 'app/pages/idle-or-error';
import LoadingScreen from 'ui/components/molecules/loading-screen';

import logger from 'utils/logging';

import useDismissEvent from 'app/hooks/use-dismiss-event';

import { ScheduleSyncScheduleQuery } from 'app/in-studio/types/graphql';

import { ScheduleSyncSchedule as SCHEDULE_QUERY } from 'app/in-studio/graphql/queries/schedule-lesson-instance.gql';

const SchedulePage = () => {
  // Include those classes that have started in the last hour so that they can be rejoined.
  const [fromTime, setFromTime] = useState<string>((subHours(new Date(Date.now()), 1)).toISOString());
  const [toTime, setToTime] = useState(startOfDay(addDays(new Date(fromTime), 1)).toISOString());
  const [selectedDayNumber, setSelectedDayNumber] = useState(format(new Date(), 'dd'));

  useDismissEvent();

  const updateDayNumber = (day: string) => {
    const fromTimeNow = new Date();
    const fromTimeStartOf = startOfDay(new Date(day));

    // Include those classes that have started in the last hour so that they can be rejoined.
    const updatedFromTime = (
      isAfter(fromTimeNow, fromTimeStartOf)
        ? subHours(fromTimeNow, 1)
        : fromTimeStartOf
    ).toISOString();
    const toTimeUpdated = addDays(new Date(fromTimeStartOf), 1).toISOString();
    setFromTime(updatedFromTime);
    setToTime(toTimeUpdated);
    setSelectedDayNumber(format(new Date(day), 'dd'));
  };

  const { loading, error, data } = useQuery<ScheduleSyncScheduleQuery>(SCHEDULE_QUERY, {
    variables: { from: fromTime, to: toTime },
    onError: (e) => logger.error('ScheduleSyncScheduleQuery error', { error: e }),
  });

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <Idle />;
  }

  const instances = data?.auth?.user?.lessonInstanceSignUps.edges
    ? data.auth.user.lessonInstanceSignUps.edges.map(({ node }) => node)
    : [];

  const canScheduleLiveClasses = data?.auth?.permissions.canScheduleLiveClasses ?? false;

  return (
    <ScheduleDisplay
      instances={instances}
      updateDayNumber={updateDayNumber}
      selectedDayNumber={selectedDayNumber}
      canScheduleLiveClasses={canScheduleLiveClasses}
    />
  );
};

export default SchedulePage;
