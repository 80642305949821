import React, { FC, Fragment, useState } from 'react';
import moment from 'moment';
import { format, isPast, isFuture } from 'date-fns';
import { rem } from 'ui/helpers';
import { pluralise } from 'ui/components/utils/pluralise';

import styled from 'styled-components';
import RepeatIcon from 'ui/components/atoms/icons/repeat';
import EditIcon from 'ui/components/atoms/icons/edit';
import UpdateClassesModal from 'app/in-studio/pages/lesson/schedule-modals/update-classes-modal';
import Typography from 'ui/components/atoms/typography';
import ClassLength from 'ui/components/atoms/class-length';

import useCountdown from 'utils/use-countdown';

import {
  GroupClassType,
  LeaderboardDisplayType,
  LessonDurationRange,
  LessonInstancePlaybackType,
  SchedulePeriod,
} from 'types/graphql';

type Instance = {
  id: string,
  workoutStartTime?: string | null,
  lobbyDurationSeconds?: number | null,
  playbackType: LessonInstancePlaybackType,
  leaderboardDisplayType?: LeaderboardDisplayType | null,
  groupClassType: GroupClassType,
  userSignUps: {
    totalCount: number,
  }
  lesson: {
    name: string,
    trainers: Array<{
      firstname: string,
      lastname: string,
    }>,
    mainImage?: {
      url: string,
    } | null,
    durationRange: LessonDurationRange,
    supportsLive: boolean,
    duration: number,
  },
  schedule?: {
    id: string,
    startsAt: string,
    endsAt: string,
    schedulePeriod: SchedulePeriod,
  } | null,
};

export type Props = {
  instance: Instance,
  canScheduleLiveClasses: boolean,
  joinAction?: () => void;
  joinText: string | null;
};

const ScheduleBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;

const SplitBox = styled.div`
  display: flex;
`;

const Image = styled.div<{ url?: string}>`
  height: ${rem(216)};
  width: ${rem(216)};
  margin-right: 20px;
  background-image: url('${({ url }) => url}');
  background-size: cover;
  background-position: center;
`;

const ScheduledClassInfoBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoldText = styled(Typography)`
  margin-bottom: 8px;
  line-height: 1.25;
`;

const ScheduleInfoText = styled(Typography)`
  margin-bottom: 8px;
`;

const StartsInText = styled(ScheduleInfoText)`
  text-align: right;
`;

const BookBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

const Divider = styled.hr`
  height: 1px;
  margin: ${rem(24)} 0;
  color: white;
`;

const StyledClassLength = styled(ClassLength)`
  padding: 0;
`;

const RepeatText = styled.span`
  margin-left: 0.25rem;
`;

const ScheduleInfoTextWithIcon = styled(ScheduleInfoText)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Resume = styled.div`
  cursor: pointer;
`;

type StartsInProps = {
  workoutStartTime: string,
};

const StartsIn = ({ workoutStartTime }: StartsInProps) => {
  const timeLeft = useCountdown(workoutStartTime);

  const getText = () => {
    if (isPast(new Date(workoutStartTime))) {
      return 'Started';
    }

    const totalMins = Math.floor(timeLeft / 60);
    const hours = Math.floor(totalMins / 60);
    const mins = totalMins % 60;

    return `Starts in ${hours}h ${mins}min`;
  };

  return (
    <StartsInText>
      <div>{getText()}</div>
    </StartsInText>
  );
};

export const ScheduledClass: FC<Props> = ({
  instance: {
    id,
    lesson,
    workoutStartTime,
    playbackType,
    leaderboardDisplayType,
    groupClassType,
    userSignUps,
    schedule,
  },
  canScheduleLiveClasses,
  joinAction,
  joinText,
}: Props) => {
  const trainers = lesson.trainers.map(({ firstname, lastname }) => (
    `${firstname} ${lastname}`
  )).join(', ');

  const hourFormatted = workoutStartTime ? format(new Date(workoutStartTime), 'h:mma') : '';

  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <Fragment key={id}>
      { showEditModal && (
        <UpdateClassesModal
          onDismiss={() => setShowEditModal(false)}
          lessonInstance={{
            id,
            playbackType,
            leaderboardDisplayType,
            groupClassType,
            workoutStartTime,
          }}
          schedule={schedule}
          supportsLive={lesson.supportsLive && canScheduleLiveClasses}
        />
      )}
      <ScheduleBoxContainer>
        <SplitBox>
          <div>
            <Image url={lesson?.mainImage?.url} />
          </div>
          <ScheduledClassInfoBox>
            <BoldText variant="body-copy">{hourFormatted}</BoldText>
            <BoldText variant="body-copy">{lesson.name}</BoldText>
            <ScheduleInfoText color="beckersKnop">{trainers}</ScheduleInfoText>
            <ScheduleInfoText>
              <StyledClassLength duration={lesson.durationRange} showIcon color="beckersKnop" className="length" />
            </ScheduleInfoText>
            <ScheduleInfoText color="beckersKnop">
              {userSignUps.totalCount} {pluralise(userSignUps.totalCount, 'person', 'people')} booked in
            </ScheduleInfoText>
            {joinAction && joinText ? <Resume onClick={joinAction}>{joinText}</Resume> : null}
            { schedule && (
              <>
                <ScheduleInfoTextWithIcon color="beckersKnop">
                  <RepeatIcon />
                  <RepeatText>{`Repeats ${schedule.schedulePeriod.toLowerCase().replace('_', ' ')}`}</RepeatText>
                </ScheduleInfoTextWithIcon>
                <ScheduleInfoText color="beckersKnop">
                  {`Ends ${moment.utc(schedule.endsAt).format('DD MMMM YYYY')}`}
                </ScheduleInfoText>
              </>
            )}
          </ScheduledClassInfoBox>
        </SplitBox>
        { workoutStartTime &&
          (
            <BookBox>
              {StartsIn({ workoutStartTime })}
              {
                workoutStartTime && isFuture(new Date(workoutStartTime))
                  ? <EditIcon onClick={() => setShowEditModal(true)} />
                  : null
              }
            </BookBox>
          )}
      </ScheduleBoxContainer>
      <Divider />
    </Fragment>
  );
};

export default ScheduledClass;
